import { HTMLAttributes } from 'react';
import { getClasses } from '@/utils';

export type RateDisplayProps = { children: number } & HTMLAttributes<HTMLSpanElement>;

const RateDisplay = ({ children, ...spanProps }: RateDisplayProps): JSX.Element => {
  const rate = parseFloat(`${children}`).toFixed(2) || '0.00';
  const [dollars = '0', cents = '0'] = `${rate}`.split('.');
  const formattedDollars = parseInt(dollars).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formattedCents = cents.padEnd(2, '0');

  return (
    <span {...spanProps} className={getClasses('RateDisplay', spanProps.className)}>
      <span>{formattedDollars}</span>
      {parseInt(formattedCents) > 0 && <sup>{formattedCents}</sup>}
    </span>
  );
};

export default RateDisplay;

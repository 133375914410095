import './styles.scss';

import { Button, Modal } from 'react-bootstrap';
import React, { ReactNode } from 'react';

import { LoadingBlur } from '../../components/LoadingSpinner';
import TippyWhen from '../../components/TippyWhen';
import { getClasses } from '@/utils';
import useConfirmation from '../../hooks/useConfirmation';

/* EditModal
  This is a complete rework/refactor of our existing EditModal component. The
  new design/concept behind this rework is that it no longer controls the state
  of what is being edited, but instead just provides a simple Modal wrapper
  so that all of our modals can have a standard design/workflow.

  The icon prop has been updated to handle either a string OR a JSX component.

  This should not need to be modified to add any new logic or functionality as
  it is only meant to servve as a shell/wrapper for Bootstrap's Modal component.

  NOTE:
    This component is not meant to be used on its own. It is designed to be wrapped
    in another component that will handle all of its logic.
*/

type headerOptions = {};
type bodyOptions = {};
type footerOptions = {
  cancelButtonVariant?: string;
  cancelButtonText?: string | JSX.Element;
  submitButtonVariant?: string;
  submitButtonText?: string | JSX.Element;
  submitButtonTooltip?: string;
  condensed?: boolean;
};
type Options = {
  Header?: JSX.Element;
  Body?: JSX.Element;
  Footer?: JSX.Element;
  header?: headerOptions;
  body?: bodyOptions;
  footer?: footerOptions;
};
type Props = {
  show?: boolean;
  title?: string;
  subtitle?: ReactNode;
  icon?: any;
  size?: 'xl' | 'sm' | 'lg';
  changes?: boolean;
  onHide?: any;
  onCancel?: any;
  onExited?: any;
  onSubmit?: any;
  options?: Options;
  children?: any;
  loading?: boolean;
  name?: string; //TODO: remove this
  className?: string;
};
const EditModal: (props: Props) => JSX.Element = ({
  show = false,
  title,
  subtitle,
  icon,
  size,
  changes = false,
  onHide,
  onCancel,
  onExited,
  onSubmit,
  options = {},
  children = [],
  loading,
  name = 'editModal',
  className,
}: Props): JSX.Element => {
  const confirmChanges = useConfirmation({
    Body: { title: 'Are You Sure?', message: 'You have unsaved changes that will be lost. Are you sure?' },
  });
  const handleSubmit = async (): Promise<void> => {
    if (!onSubmit) return;
    onSubmit();
  };
  const handleHide = async (): Promise<void> => {
    if (!onHide) return;
    try {
      if (changes) await confirmChanges();
      onHide();
    } catch (err) {
      console.error(err);
    }
  };
  const handleCancel = async (): Promise<void> => {
    if (!onCancel) return await handleHide();
    try {
      if (changes) await confirmChanges();
      onCancel();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Modal
        className={getClasses('EditModal', name, className)}
        show={show}
        onHide={onHide ? handleHide : undefined}
        onExited={onExited}
        size={size}
      >
        <LoadingBlur loading={loading} />
        {onHide && <Button className="btn-close {position:absolute;top:1rem;right:1rem;}" variant="" onClick={handleHide} />}
        {options?.Header ? options?.Header : null}
        {options?.Body ? (
          options?.Body
        ) : (
          <Modal.Body>
            <div className="ErrorContainer" />
            {(title || icon) && (
              <Modal.Title>
                {icon && (typeof icon === 'string' ? <i className={`${icon} me-2`} /> : icon)}
                <span>{title}</span>
                {subtitle && <span style={{ float: 'right' }}>{subtitle}</span>}
              </Modal.Title>
            )}
            {children}
          </Modal.Body>
        )}
        {options?.Footer !== undefined ? (
          options?.Footer
        ) : (
          <Modal.Footer className={options?.footer?.condensed ? 'justify-content-end gap-0' : 'justify-content-center px-5'}>
            {(onCancel || onHide) && (
              <Button
                name="CANCEL"
                className={`${options?.footer?.condensed ? 'me-2 {min-width:10rem;}' : 'flex-grow-1 mx-4 '}`}
                variant={options?.footer?.cancelButtonVariant || 'secondary'}
                onClick={handleCancel}
              >
                {options?.footer?.cancelButtonText || 'Cancel'}
              </Button>
            )}
            {onSubmit !== undefined && (
              <TippyWhen
                isTrue={!onSubmit}
                options={{ content: options?.footer?.submitButtonTooltip || 'No changes have been made, or fields are invalid.' }}
              >
                <div className={`${options?.footer?.condensed ? '{min-width:10rem;}' : 'flex-grow-1 mx-4'}`}>
                  <Button
                    name="SUBMIT"
                    className="w-100"
                    variant={options?.footer?.submitButtonVariant || 'primary'}
                    onClick={handleSubmit}
                    disabled={!onSubmit}
                  >
                    {options?.footer?.submitButtonText || 'Done'}
                  </Button>
                </div>
              </TippyWhen>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default React.memo(EditModal);
export { Props as EditModalProps };

import { Datetime, QueryInputType, queryInput } from '@/utils';
import { FcrTypeEdge, SortDirectionEnum } from '@/models/gen/graphql';
import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'react-bootstrap';
import DynamicContent from '@/components/DynamicContent';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { SearchFcrTypes } from '@/api/queries/fcrs/SearchFcrTypes';
import TextArea from '@/components/TextArea';
import { TripModalViewProps } from '@/features/Trips/components/TripModal';
import createFcrBulk from '@/api/services/trips/createFcrBulk';
import useDb from '@/hooks/useDb';
import { useSearchFcrs } from '@/api/services/fcrs/searchFcrs';

const IncidentView = ({ data: trip, title, onSubmit }: TripModalViewProps) => {
  const [text, setText] = useState<string>('');
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const [{ data, loading: loadingFcrs }, { refetch: getFcrs }] = useSearchFcrs();
  const existingFcr = data?.rows?.[0];
  const loading = submittingForm || loadingFcrs;

  const reportTemplate: string =
    title === 'Incident' ? incidentReportTemplate : title === 'Accident' ? accidentReportTemplate : lostAndFoundReportTemplate;

  const { data: fcrTypesData = {} } = useDb('FcrTypes', SearchFcrTypes);
  const fcrTypeId: string = useMemo(
    () =>
      (fcrTypesData.searchFcrTypes?.fcrTypeConnection?.edges || []).find(
        (fcrTypeEdge: FcrTypeEdge) => fcrTypeEdge?.node?.name === title?.replace?.(/\s+/g, '')
      )?.node?.id,
    [fcrTypesData, title]
  );

  useEffect(() => {
    setText(existingFcr?.description || reportTemplate);
  }, [reportTemplate, existingFcr]);

  useEffect(() => {
    if (!fcrTypeId || !trip?.id) return;
    getFcrs([
      {
        fcrTypeId: queryInput(fcrTypeId),
        tripId: queryInput(trip?.id),
        createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc),
      },
    ]);
  }, [fcrTypeId, trip?.id]);

  const handleSubmit = async (): Promise<void> => {
    setSubmittingForm(true);
    try {
      await createFcrBulk([
        {
          fcrTypeId,
          tripId: trip?.id,
          description: text,
          cause: '',
        },
      ]);
      onSubmit({});
    } catch (error) {
      console.error(error);
    }
    setSubmittingForm(false);
  };

  return (
    <div className="IncidentView">
      <LoadingBlur loading={loading} />
      <div className="text-primary">
        {trip?.servicerIataAirlineCode}
        {trip?.flightNumber} {new Datetime(trip?.scheduled).frontendDatetime}
      </div>
      <DynamicContent.Title>{title}</DynamicContent.Title>
      <TextArea value={loadingFcrs ? '' : text} onChange={setText} options={{ disableResize: true }} readOnly={!!existingFcr} />
      <Button variant="green" className="my-2 w-100" disabled={text === reportTemplate || !fcrTypeId} onClick={handleSubmit}>
        Report {title === 'Lost And Found' ? 'Lost Item' : title}
      </Button>
    </div>
  );
};

const incidentReportTemplate = `
Description of incident:


Date:
Time:
Samsara Camera Working?
Employee ID:
Employee Name:
Van Number:
Station:
Manager on duty:
Were crew involved?
Flight Number:
Airline:
Flight Time:
Outbound Flight #/ Dep Time:
Information of all crew members:


Recovery Info:

Anyone injured?

Information of all injured parties:



Medical Needed?

What type of medical attention was offered/required?

Property Damage?
Where did this occur?

Describe Damage:


Witnesses: (Names, Phones, Emails)
`;

const accidentReportTemplate = `
Description of accident:


Date:
Time:
Samsara Camera Working?
Employee ID:
Dispatcher
Driver:
Van Number:
Station:
Specific location of the accident:
Flight Number:
Airline:
Was 3rd party involved?
3rd party information:


Describe damage to other vehicles:

Anyone injured?

Medical Needed?

What type of medical attention was offered/required?


Information of all injured parties:


Were crew involved?

Information of all crew members:

Did driver call 911?
Can vehicle be driven?
Was driver at fault?
Was Kristine called?
Which of these apply?
Arranged another driver to transport crew members?
Driver contacted manager?
You contacted manager?
Who is safety on call?
Was safety notified?
`;

const lostAndFoundReportTemplate = `
Description of item:


Date:
Time:
Dispatcher:
Driver:
Station:
Van Number:
Airline:
Flight Number:
Description:
Crew Members Name:
Crew Members Phone Number:
Crew Members Email:
Next Steps:
`;

export default IncidentView;

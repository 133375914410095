import { InvoicePreviewTableProvider, useInvoicePreviewTable } from '@/pages/InvoicePreview/InvoicePreviewTable';

import { InvoiceTrip } from '@/models/gen/graphql';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import useSession from '@/state/session';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';

const CrewIdCell = (): JSX.Element => {
  const [payerProviderId] = useInvoicePreviewTable<string>(({ payerProviderId }: InvoicePreviewTableProvider) => payerProviderId);
  const {
    data: { crewId, firstName, lastName },
  } = useSimpleTableRowContext<InvoiceTrip>();
  const upsProviderId = useSession(({ state }) => state?.config?.upsProviderId);
  const isUpsProvider = payerProviderId === upsProviderId;
  const displayParts = [(crewId || '--')?.toString?.() || '--'];
  if (isUpsProvider) displayParts.push(firstName, lastName);
  const display = displayParts
    .filter((str) => !!str)
    .join(' ')
    .trim();

  return <SimpleTableCell name="CREW_ID">{display}</SimpleTableCell>;
};

export default CrewIdCell;

import { Button, Collapse } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import VirtualTable, { DynamicCell, ExpandCell, VirtualTableRow, useVirtualTable } from '../../components/VirtualTable';

import { InvoiceSummary } from '../../models/gen/graphql';
import Tippy from '@tippyjs/react';
import { formatDollars } from '../../utils/numbers';
import { getClasses } from '../../utils/strings';

type Props = {
  summary: InvoiceSummary;
};
type State = {
  expanded: string[];
  show: boolean;
};
const initState: State = {
  expanded: [],
  show: false,
};
const InvoicePreviewSummary = ({ summary }: Props) => {
  const [{ show, expanded }, setState] = useState(initState);
  const [rows] = useMemo((): any => {
    const result = [];
    result.push({
      ...summary,
      id: `${0}_${summary?.client}`,
      code: summary?.client,
      children: (summary?.airports || []).map((airport: any, id: number): any => ({
        ...airport,
        children: (airport?.rates || []).map((rate: any): any => ({
          ...rate,
          rate: true,
          code: `At ${formatDollars(rate?.amount)}`,
          average: rate?.total / rate?.count,
        })),
        id: `${0}_${summary?.client}_${id}`,
      })),
    });
    return [result];
  }, [summary]);

  const { onExpand } = useVirtualTable(setState, {
    rows: rows || [],
    expanded,
  });

  return (
    <>
      {!!summary && (
        <>
          <div className="d-flex justify-content-center gap-4 p-3 bg-white">
            <Tippy content={`${show ? 'Hide' : 'View'} Summary Report`}>
              <Button
                className="position-relative d-print-none"
                variant="primary"
                size="sm"
                onClick={() => setState((current: State): State => ({ ...current, show: !show }))}
              >
                <i className={`fa fa-${show ? 'chevron-up' : 'chevron-down'}`} />
              </Button>
            </Tippy>
            <div className="fs-4">Summary Report</div>
          </div>
          <Collapse in={show}>
            <div className="InvoicePreviewSummaryTable bg-glass ">
              <VirtualTable
                name="invoicePreviewSummary"
                header={{
                  code: 'City',
                  count: 'Trips',
                  average: 'Average',
                  total: 'Total',
                }}
                data={rows}
                expanded={expanded}
                dynamic
                rowRenderer={({
                  index,
                  data: { _type, ...data } = {},
                  context = {},
                }: {
                  index: any;
                  data: any;
                  context: any;
                }): JSX.Element => {
                  return (
                    <VirtualTableRow
                      context={{
                        ...context,
                        rowType: _type,
                        data,
                        index,
                        expanded: !!expanded.includes(data?.id || index),
                      }}
                      className={getClasses(
                        expanded.includes(data?.id || index) ? 'expanded' : '',
                        `${data?.id || index}`.includes('_') ? 'nested' : '',
                        !data?.children?.length ? 'no-children' : ''
                      )}
                      onDoubleClick={(rows || []).length ? (): void => onExpand(data?.id || index) : undefined}
                    >
                      <ExpandCell onClick={onExpand} />
                      <DynamicCell
                        selector="code"
                        className="text-center"
                        width="calc(100% / 4)"
                        render={({ value, data }: { value: string; data: any }): any =>
                          data?.rate ? (
                            <div key={value} className="text-end">
                              {value}
                            </div>
                          ) : (
                            value
                          )
                        }
                      />
                      <DynamicCell selector="count" className="text-center" width="calc(100% / 4)" />
                      <DynamicCell
                        selector="average"
                        className="text-center"
                        width="calc(100% / 4)"
                        render={({ value }: { value: string }): string => formatDollars(isNaN(parseFloat(value)) ? 0 : value)}
                      />
                      <DynamicCell
                        selector="total"
                        className="text-center"
                        width="calc(100% / 4)"
                        render={({ value }: { value: string }): string => formatDollars(isNaN(parseFloat(value)) ? 0 : value)}
                      />
                    </VirtualTableRow>
                  );
                }}
              />
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

export default InvoicePreviewSummary;

import './styles.scss';

import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { Datetime, Validation, onEnter, parsePhoneNumber } from '@/utils';
import React, { HTMLAttributes, useMemo, useState } from 'react';

import ConfirmationButton from '@/components/ConfirmationButton';
import DateInput from '@/components/DateInput';
import FormField from '@/components/FormField';
import { InvoiceDocument } from '@/models/gen/graphql';
import { LoadingBlur } from 'components/LoadingSpinner';
import TagInput from '@/components/TagInput';
import TippyWhen from '@/components/TippyWhen';
import { formatDollars } from '@/utils/numbers';
import { getClasses } from '@/utils/strings';
import useLocalStorage from '@/hooks/useLocalStorage';

const InvoicePreviewHeader = ({ loading = false, data, onChange, onDownload, onSave, onSend, onSaveAs }: any): JSX.Element => {
  const [state, setState] = useState({ expanded: false, newHeaderName: '', invoicedOpen: false, dueOpen: false, validEmail: true });
  const { expanded, newHeaderName, validEmail } = state;

  const {
    invoiceTerms,
    documents = [],
    airports = [],
    headers = [],
    grandTotal = 0,
    emails = [],
    name = '',
    id = '',
    invoiced = '',
    due = '',
    startDatetime = '',
    endDatetime = '',
    company: {
      displayName: companyName = '',
      address: companyAddress = '',
      cityName: companyCity = '',
      stateCode: companyStateCode = '',
      zipCode: companyZipCode = '',
      invoicePhone: companyPhone = '',
      invoiceEmail: companyEmail = '',
      website: companyWebsite = '',
    } = {},
    payerProvider: {
      displayName: providerName = '',
      billingAddress: providerAddress = '',
      billingCityCode: providerCity = '',
      billingStateCode: providerStateCode = '',
      billingZipCode: providerZipCode = '',
      primaryEmail: providerEmail = '',
      primaryLogoUrl: providerLogo = '',
      referenceNumber: companyVendorNumber = '',
    } = {},
  } = data || {};

  const validateEmail = (value: string): boolean => {
    const isValid = Validation.isEmail(value.trim()) || value.trim() === '';
    setState((current: any): any => ({ ...current, validEmail: isValid }));
    return isValid;
  };

  const [storedAirports] = useLocalStorage('airports', { items: [] });
  const allAirports = useMemo(
    () =>
      storedAirports?.items
        ?.filter((airport) => !airport?.group && !!airport?.value)
        ?.map((airport) => airport?.value)
        ?.join?.(', ') || '',
    [storedAirports]
  );

  const lastSentDatetime = useMemo(() => {
    if (!documents?.length) return undefined;
    const lastSentDocument = [...documents].reverse().find((document: InvoiceDocument) => !!document?.sentAt);
    if (!lastSentDocument) return undefined;
    return new Datetime().setAsUTC(lastSentDocument?.sentAt).toLocaleDatetime();
  }, [documents]);

  return (
    <Card className="InvoicePreview-Header w-75 mx-auto mb-4 shadow primary border-2">
      <Card.Body className="{position:relative;}">
        <LoadingBlur loading={loading} />
        <Row className="d-flex">
          <Col xl={3}>
            {providerLogo && (
              <div
                className="{border-right:1px|#CCC|solid;}@>=1200 {position:relative;width:100%;height:100%;}"
                style={{
                  backgroundImage: `url(${providerLogo})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
          </Col>
          <Col xl={8} className="text-primary">
            <Collapse in={expanded}>
              <Row>
                <Col>
                  <FormField
                    name="name"
                    label="Invoice Name:"
                    value={name}
                    readOnly
                    options={{
                      input: { className: 'text-primary' },
                      label: { className: 'text-primary' },
                    }}
                  />
                </Col>
              </Row>
            </Collapse>
            <Row>
              <Col md={5}>
                <Summary>
                  <Summary.Item title="Transportation Invoice:" />
                  <Summary.Item>{companyName}</Summary.Item>
                  <Summary.Item label="Vendor Number:">{companyVendorNumber || 'NA'}</Summary.Item>
                </Summary>
                <Summary in={expanded}>
                  {companyAddress && <Summary.Item>{companyAddress}</Summary.Item>}
                  {companyCity && companyStateCode && <Summary.Item>{`${companyCity}, ${companyStateCode}`}</Summary.Item>}
                  {companyZipCode && <Summary.Item>{companyZipCode}</Summary.Item>}
                  {!companyAddress && !companyCity && !companyStateCode && !companyZipCode && <Summary.Item>(No Address)</Summary.Item>}
                  {companyPhone && <Summary.Item>{parsePhoneNumber(companyPhone, ' ')} Accounting Dept.</Summary.Item>}
                  {companyEmail && <Summary.Item>{companyEmail}</Summary.Item>}
                  {companyWebsite && <Summary.Item>{companyWebsite}</Summary.Item>}
                </Summary>
                <Summary>
                  <Summary.Item>&nbsp;</Summary.Item>
                  <Summary.Item title="Total Amount Due:">USD&nbsp;{formatDollars(grandTotal)}</Summary.Item>
                </Summary>
              </Col>
              <Col md={7}>
                <Summary className="mb-2" in={expanded}>
                  <Summary.Item title="Bill To:" />
                  {providerName && <Summary.Item>{providerName}</Summary.Item>}
                  {providerAddress && <Summary.Item>{providerAddress}</Summary.Item>}
                  {providerCity && providerStateCode && <Summary.Item>{`${providerCity}, ${providerStateCode}`}</Summary.Item>}
                  {providerZipCode && <Summary.Item>{providerZipCode}</Summary.Item>}
                  {!providerAddress && !providerCity && !providerStateCode && !providerZipCode && <Summary.Item>(No Address)</Summary.Item>}
                  <Summary.Item label="E-Mail:">{providerEmail}</Summary.Item>
                  <Summary.Item label="City of Service:">{airports.length > 0 ? airports.join(', ') : allAirports}</Summary.Item>
                </Summary>
                <Summary className="{font-size:1.25rem!;padding:0!;line-height:1.2!;}_.ant-picker-input>input">
                  <Summary.Item>
                    <DateInput
                      name="invoiced"
                      className="border-0 outline-none shadow-none {flex-direction:row-reverse;margin-left:-1.7rem;}>.ant-picker-input"
                      onChange={onChange}
                      value={new Datetime(invoiced).toString()}
                      suffixIcon={
                        <span className="{color:var(--bs-primary);}">
                          <i className="fa fa-regular fa-calendar fs-6 me-2" />
                          <strong className="{font-size:1rem;}">Invoice Date: </strong>
                        </span>
                      }
                    />
                  </Summary.Item>
                  <Summary.Item>
                    <DateInput
                      name="due"
                      className="border-0 outline-none shadow-none {flex-direction:row-reverse;margin-left:-1.7rem;}>.ant-picker-input"
                      onChange={onChange}
                      value={new Datetime(due).toString()}
                      suffixIcon={
                        <span className="{color:var(--bs-primary);}">
                          <i className="fa fa-regular fa-calendar fs-6 me-2" />
                          <strong className="{font-size:1rem;}">Due Date: </strong>
                        </span>
                      }
                    />
                  </Summary.Item>
                  <Summary.Item label="Billing Period:">
                    {new Datetime(startDatetime).frontendDate}&nbsp;&mdash;&nbsp;{new Datetime(endDatetime).frontendDate}
                  </Summary.Item>
                  <Summary.Item label="Invoice Number: ">{id || 'N/A'}</Summary.Item>
                  <Summary.Item label="Invoice Terms:">{invoiceTerms}</Summary.Item>
                </Summary>
                <Summary in={expanded} className="mt-3">
                  {headers.map(
                    (header: any, i: number): JSX.Element => (
                      <HeaderInput
                        key={i}
                        value={header.name}
                        onChange={(event: any): void =>
                          onChange({
                            target: {
                              name: 'headers',
                              value: [
                                ...headers.map((header: any, index: number): any => (index !== i ? header : { name: event.target.value })),
                              ],
                            },
                          })
                        }
                        onRemove={(): void =>
                          onChange({
                            target: { name: 'headers', value: headers.filter((_: any, index: number): boolean => index !== i) },
                          })
                        }
                      />
                    )
                  )}
                  <HeaderInput
                    value={newHeaderName}
                    onChange={(event: any): void => setState((current: any): any => ({ ...current, newHeaderName: event.target.value }))}
                    onAdd={(event: any): void => {
                      onChange({ target: { name: 'headers', value: [...headers, { name: newHeaderName }] } });
                      setState((current: any): any => ({ ...current, newHeaderName: '' }));
                      event?.target?.blur?.();
                    }}
                  />
                </Summary>
              </Col>
            </Row>
          </Col>
          <Col xl={1}>
            <Button
              variant="primary"
              onClick={(): void => setState((current: any): any => ({ ...current, expanded: !current?.expanded }))}
              style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
              <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex gap-5 px-3 py-4 border-2 {white-space:nowrap;}>*">
        <FormField
          name="emails"
          label="Send To:"
          value={emails}
          onChange={(value: string[]): void =>
            onChange({
              target: { name: 'emails', value: value?.map?.((address: string): string => address) },
            })
          }
          options={{
            input: {
              as: (props: any): JSX.Element => <TagInput {...props} onValidate={validateEmail} />,
            },
          }}
          valid={validEmail}
          feedback={!validEmail ? 'Please enter a valid email address.' : undefined}
          inline
          condensed
        />
        <div className="d-flex gap-2 ps-3 align-items-center">
          <span>
            <Button variant="outline-primary" onClick={onDownload} disabled={loading}>
              <i className="fa fa-download" />
            </Button>
          </span>
          <span>
            {!id && (
              <Button variant="outline-primary" onClick={onSave} disabled={loading}>
                {loading ? <i className="fa fa-spinner fa-pulse" /> : 'Save'}
              </Button>
            )}
            {!!id && (
              <ConfirmationButton
                variant="outline-primary"
                onConfirm={onSave}
                disabled={loading}
                options={{
                  confirmation: { Body: { message: 'You are about to override a saved invoice. Are you sure you want to do this?' } },
                }}
              >
                {loading ? <i className="fa fa-spinner fa-pulse" /> : 'Save'}
              </ConfirmationButton>
            )}
          </span>
          {onSaveAs && (
            <span>
              <ConfirmationButton
                variant="outline-primary"
                onConfirm={onSaveAs}
                disabled={loading}
                options={{
                  confirmation: {
                    Body: {
                      message: "This invoice exists already and you're about to create a duplicate. Are you sure you want to do this?",
                    },
                  },
                }}
              >
                Duplicate
              </ConfirmationButton>
            </span>
          )}
          <TippyWhen
            isTrue={!emails?.length || !!lastSentDatetime}
            options={{
              theme: 'primary',
              content: !emails?.length ? (
                'You must enter at least one email address.'
              ) : (
                <div>
                  LAST SENT ON {lastSentDatetime?.frontendDate} AT {lastSentDatetime?.time}
                </div>
              ),
            }}
          >
            <span>
              <ConfirmationButton
                variant={lastSentDatetime ? 'green' : 'primary'}
                name="SAVE_AND_SEND"
                onConfirm={onSend}
                disabled={!emails?.length}
                options={{ confirmation: { Body: { message: 'Are you sure you want to send this invoice?' } } }}
              >
                Save & Send{lastSentDatetime ? ` Again` : ''}
              </ConfirmationButton>
            </span>
          </TippyWhen>
        </div>
      </Card.Footer>
    </Card>
  );
};

const Summary = ({ in: expanded, ...props }: any): JSX.Element => {
  const result = (
    <ul
      {...props}
      className={getClasses(
        'list-unstyled',
        '',
        'm-0 {margin:0.25rem|0;}>li {line-height:1!;}_h5 {margin-left:0.5rem;}_strong+span',
        props?.className
      )}
    />
  );
  if (expanded !== undefined) return <Collapse in={expanded}>{result}</Collapse>;
  return result;
};
const SummaryItem = ({
  title,
  label,
  children,
  ...props
}: Omit<HTMLAttributes<HTMLLIElement>, 'title'> & {
  title?: string | JSX.Element;
  label?: string | JSX.Element;
  children?: any;
  [key: string]: any;
}): JSX.Element => {
  const content = useMemo(() => {
    const result = (
      <>
        {(!!title || !!label) && <strong>{title || label}</strong>}
        {(!!title || !!label) && children && <span>{children}</span>}
        {!title && !label && children && <>{children}</>}
      </>
    );
    return title ? <h6>{result}</h6> : result;
  }, [title, label, children]);

  return (
    <li {...props} className={getClasses(props?.className || '', `{position:relative;left:${props?.offset || 0}rem;}`)}>
      {content}
    </li>
  );
};
Summary.Item = SummaryItem;

const HeaderInput = ({
  value,
  onChange,
  onAdd,
  onRemove,
}: {
  value: string;
  onChange: (event: any) => void;
  onAdd?: (event: any) => void;
  onRemove?: (event: any) => void;
}): JSX.Element => {
  const handleBlur = (event) => {
    if (onAdd && !!value?.length) return onAdd(event);
    if (onRemove && !value?.length) return onRemove(event);
  };
  return (
    <Summary.Item className="d-flex gap-2" offset={-2.5}>
      <TippyWhen isTrue={!!onAdd && !value?.length} options={{ content: 'Name cannot be blank.' }}>
        <span>
          <Button
            size="sm"
            variant={`outline-${onAdd ? 'success' : 'danger'}`}
            onClick={onAdd || onRemove}
            disabled={!!onAdd && !value?.length}
          >
            <i className={`fa fa-${onAdd ? 'plus' : 'minus'}`} />
          </Button>
        </span>
      </TippyWhen>
      <Form.Control
        value={value || ''}
        placeholder="Name"
        size="sm"
        onChange={onChange}
        onKeyDown={(event: any): void => (!!value?.trim?.() && !!onAdd ? onEnter(onAdd)(event) : undefined)}
        onBlur={handleBlur}
      />
    </Summary.Item>
  );
};

export default React.memo(InvoicePreviewHeader);

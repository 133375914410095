import { Datetime, getClasses, zeroPadFlightNumber } from '@/utils';
import React, { ForwardedRef, useCallback } from 'react';
import SimpleTableRow, { SimpleTableRowProps } from '@/components/SimpleTable/SimpleTableRow';
import { TrackFlightTypeEnum, Trip } from '@/models/gen/graphql';
import useTripSettings, { ColumnKeyEnum } from '@/features/Trips/components/TripSettingsModal/hook';

import ActivityCell from '@/features/Trips/components/TripsTable/Cells/ActivityCell';
import ActualCell from '@/features/Trips/components/TripsTable/Cells/ActualTimeCell';
import AssignDriverCell from '@/features/Trips/components/TripsTable/Cells/AssignDriverCell';
import AssignVehicleCell from '@/features/Trips/components/TripsTable/Cells/AssignVehicleCell';
import CombineCell from '@/features/Trips/components/TripsTable/Cells/CombineCell';
import CompletionCell from '@/features/Trips/components/TripsTable/Cells/CompletionCell';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import FcrCell from '@/features/Trips/components/TripsTable/Cells/FcrCell';
import FlagAndCommunicationCell from '@/features/Trips/components/TripsTable/Cells/FlagAndCommunicationCell';
import LocationCell from '@/features/Trips/components/TripsTable/Cells/LocationCell';
import RateCell from '@/features/Trips/components/TripsTable/Cells/RateCell';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import { isUpcomingTrip } from '@/features/Trips/components/TripsTable/utils';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

export type TripsTableRowEventHandlers = {
  onEditTrip: (data: Partial<Trip> | string, edit?: boolean) => void;
  onEditRateReport: (selected: string[]) => void;
  onEditRate: (tripId?: string, rateGroupId?: string) => void;
  onDeleteTrips: (tripIds: string[], lastFormat?: string) => void;
  onRefetch: () => Promise<void>;
};

const TripsTableRow = ({ id, ...divProps }: SimpleTableRowProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { onEditTrip, onEditRate } = useTripsTableContext();
  const columns = useTripSettings(({ state }) => state.columns);
  const data = useTripTableState(({ state }) => state.trips.get(id) || ({} as Trip));
  const isSelected = useTripTableState(({ state }) => state.selected.get(id));
  const onSelect = useTripTableState(({ state }) => state.onSelect);
  const onSelectOnly = useTripTableState(({ state }) => state.onSelectOnly);
  const onSetRow = useTripTableState(({ state }) => state.onSetRow);

  const tripScheduledDatetime = data.scheduled ? new Datetime(data.scheduled) : undefined;
  const tripScheduledUtcDatetime = data.scheduledUtc ? new Datetime(data.scheduledUtc) : undefined;
  const tripFlightNumber = data.flightNumber !== null && data.flightNumber >= 0 ? zeroPadFlightNumber(data.flightNumber) : '--';
  const tripIsSelected = isSelected ? 'SELECTED' : undefined;
  const tripKind = data?.kind ? data?.kind : undefined;
  const tripType = data?.type ? data?.type : undefined;
  const tripStatus = data?.status ? data?.status : undefined;
  const tripIsLate = data?.isLate ? 'LATE' : undefined;
  const tripIsUpcoming = tripScheduledUtcDatetime && isUpcomingTrip(tripScheduledUtcDatetime) ? 'UPCOMING' : undefined;
  const tripIsCompleted = data?.state?.completion ? 'COMPLETED' : undefined;
  const tripIsDeleted = data?.deletedAt ? 'DELETED' : undefined;
  const tripIsCurbside = data?.curbsideAt ? 'CURBSIDE' : undefined;
  const tripIsPickedup = data?.puCompletedDatetime ? 'PICKEDUP' : undefined;

  const onRowDoubleClick = useCallback((): void => {
    onSelectOnly(id);
    onEditTrip(data);
  }, [data, onEditTrip, onSelectOnly, id]);
  const onRateDoubleClick = useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      onSelectOnly(id);
      onEditRate();
    },
    [onEditRate, onSelectOnly, id]
  );
  const onSelectClick = useCallback((): void => onSelect(id), [onSelect, id]);
  const classes = getClasses(
    divProps?.className,
    tripIsSelected,
    tripKind,
    tripType,
    tripStatus,
    tripIsLate,
    tripIsUpcoming,
    tripIsCompleted,
    tripIsDeleted,
    tripIsCurbside,
    tripIsPickedup
  );

  return (
    <SimpleTableRow {...divProps} data={data} className={classes} onDoubleClick={onRowDoubleClick} ref={ref}>
      <SimpleTableCell name="select" className="w-xs-fixed d-print-none">
        <input type="checkbox" name="SimpleTable-Select" checked={!!isSelected} onChange={onSelectClick} />
      </SimpleTableCell>
      {columns.get(ColumnKeyEnum.FLAGS) && <FlagAndCommunicationCell />}
      {columns.get(ColumnKeyEnum.TYPE) && (
        <SimpleTableCell name="TYPE" className="w-md-fixed">
          {data.type || '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
        <SimpleTableCell name="SCHEDULED_DATE" className="w-sm-grow">
          {tripScheduledDatetime ? tripScheduledDatetime.format(DATE_FE_FORMAT_SHORT) : '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && (
        <SimpleTableCell name="SCHEDULED_TIME" className="w-sm-grow">
          {tripScheduledDatetime?.time ? tripScheduledDatetime.time : '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.ACTUAL) && <ActualCell />}
      {columns.get(ColumnKeyEnum.AIRPORT_CODE) && (
        <SimpleTableCell name="AIRPORT_CODE" className="w-md-fixed">
          {data.airportCode || '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.IATA_CODE) && (
        <SimpleTableCell name="IATA_CODE" className="w-sm-fixed">
          {data.servicerIataAirlineCode || '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
        <SimpleTableCell
          name="FLIGHT_NUMBER"
          className={getClasses(
            data.trackFlight?.type === TrackFlightTypeEnum.International ? 'bg-purple bg-opacity-15 text-dark' : undefined
          )}
        >
          {data.loopName || tripFlightNumber || '--'}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.PILOT) && (
        <SimpleTableCell name="PILOT" className="w-sm-fixed">
          {data.pilots || 0}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.ATTENDANT) && (
        <SimpleTableCell name="ATTENDANT" className="w-sm-fixed">
          {data.attendants || 0}
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.DRIVER) && <AssignDriverCell />}
      {columns.get(ColumnKeyEnum.VEHICLE) && <AssignVehicleCell />}
      {columns.get(ColumnKeyEnum.PU_LOCATION) && <LocationCell type="pickup" />}
      {columns.get(ColumnKeyEnum.DO_LOCATION) && <LocationCell type="dropoff" />}
      {columns.get(ColumnKeyEnum.CLIENT) && <SimpleTableCell name="CLIENT">{data.payerProvider?.displayName || '--'}</SimpleTableCell>}
      {columns.get(ColumnKeyEnum.COMPLETION) && <CompletionCell />}
      {columns.get(ColumnKeyEnum.ACTIVITY) && <ActivityCell />}
      {columns.get(ColumnKeyEnum.FCR) && <FcrCell />}
      {columns.get(ColumnKeyEnum.COMBINE) && <CombineCell />}
      {columns.get(ColumnKeyEnum.RATE) && <RateCell onSetRow={onSetRow} onDoubleClick={onRateDoubleClick} />}
    </SimpleTableRow>
  );
};

export default React.memo(React.forwardRef(TripsTableRow));

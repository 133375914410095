import './styles.scss';

import { Datetime, formatDollars, saveFile, uuid } from '@/utils';
import InvoicePreviewFilters, { InvoicePreviewFiltersState, initInvoicePreviewFiltersState } from './InvoicePreviewFilters';
import { InvoiceRateSubtotal, InvoiceTrip, RunPreviewInvoiceResponse } from '@/models/gen/graphql';
import {
  ParsedInvoiceInput,
  convertParsedInvoiceInputToInvoiceInput,
  parseGetInvoicePreviewResponse,
  useGetInvoicePreview,
} from '@/api/services/invoices';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { RouteProps, useNavigate, useParams } from 'react-router-dom';

import InvoicePreviewHeader from './InvoicePreviewHeader';
import InvoicePreviewSummary from './InvoicePreviewSummary';
import InvoicePreviewTable from './InvoicePreviewTable';
import InvoicePreviewTotals from './InvoicePreviewTotals';
import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import equal from 'fast-deep-equal/es6/react';
import useForm from '@/hooks/useForm';
import { useRunDownloadInvoice } from '@/api/services/invoices/runDownloadInvoice';
import { useRunSaveAndSendInvoice } from '@/api/services/invoices/runSaveAndSendInvoice';
import { useRunSaveAsInvoice } from '@/api/services/invoices/runSaveAsInvoice';
import { useRunSaveInvoice } from '@/api/services/invoices/runSaveInvoice';
import useViewport from '@/hooks/useViewport';

const initPreview: ParsedInvoiceInput = {
  ...initInvoicePreviewFiltersState,
  // remaining preview information
  due: new Datetime().endOf('day').toString(),
  emails: [],
  grandTotal: 0,
  headers: [],
  id: 0,
  invoiced: initInvoicePreviewFiltersState.endDatetime,
  items: [],
  name: '',
  total: 0,
  trips: [],
};

export type PreviewTrip = { id: string; rate?: string; subtotal?: string; count?: number; trip?: InvoiceTrip };
export const parseInvoicePreview = async (response: RunPreviewInvoiceResponse): Promise<ParsedInvoiceInput> => {
  const parsedResponse = parseGetInvoicePreviewResponse(response);
  const { output } = parsedResponse;
  const result: ParsedInvoiceInput = Object.keys(initPreview).reduce((acc, key) => {
    let invoice = output?.[key] || output?.invoice?.[key] || initPreview[key];
    if (Array.isArray(invoice)) invoice = Array.from(new Set(invoice));
    acc[key] = invoice;
    return acc;
  }, {} as ParsedInvoiceInput);
  return result;
};
const subtotalUuid = uuid();

const InvoicePreview = (_props: RouteProps): JSX.Element => {
  const { id: invoiceIdString } = useParams();
  const invoiceId = parseInt(invoiceIdString) || 0;
  const navigate = useNavigate();
  const [
    {
      content: { height: contentHeight },
    },
  ] = useViewport();

  const [preview, onChange, setPreview] = useForm(initPreview);
  const { format, grandTotal, total, trips, items } = preview;

  const [{ loading: saving }, { fetch: runSaveInvoice }] = useRunSaveInvoice();
  const [{ loading: savingAs }, { fetch: runSaveAsInvoice }] = useRunSaveAsInvoice();
  const [{ loading: downloading }, { fetch: runDownloadInvoice }] = useRunDownloadInvoice();
  const [{ loading: sending }, { fetch: runSaveAndSendInvoice }] = useRunSaveAndSendInvoice();
  const [{ data, loading: previewLoading }, { fetch: getInvoicePreview }] = useGetInvoicePreview();
  const loading = saving || savingAs || downloading || sending || previewLoading;
  const { invoiceTerms = '', output, subtotals = [], summary } = data || {};
  const lastFilters = useRef<InvoicePreviewFiltersState>(null);
  const lastClient = useRef<string>('');

  const onSubmit = useCallback(
    async (filters?: InvoicePreviewFiltersState): Promise<void> => {
      try {
        filters = filters || lastFilters.current || ({} as InvoicePreviewFiltersState);
        lastFilters.current = filters;
        const query: Partial<ParsedInvoiceInput> = {
          payerProviderId: filters.payerProviderId,
          iataAirlineCodes: filters.iataAirlineCodes,
          airports: filters.airports,
          types: filters.types,
          startDatetime: filters.startDatetime,
          endDatetime: filters.endDatetime,
          format: filters.format,
          tripStatus: filters.tripStatus,
        };
        const payload = convertParsedInvoiceInputToInvoiceInput({ ...preview, ...query });
        if (lastClient.current !== query.payerProviderId) payload.emails = [];
        lastClient.current = payload.payerProviderId;
        const response = await getInvoicePreview(payload);
        if (!response) return;
        parseInvoicePreview(response).then(setPreview);
      } catch (err) {
        console.error(err);
      }
    },
    [getInvoicePreview, preview, setPreview]
  );

  const saveAndLoadInvoice = useCallback(
    async (method: 'save' | 'saveAs' | 'send') => {
      const fn = method === 'save' ? runSaveInvoice : method === 'saveAs' ? runSaveAsInvoice : runSaveAndSendInvoice;
      const res = await fn(convertParsedInvoiceInputToInvoiceInput(preview));
      if (res?.invoice?.id) {
        if (method !== 'send') return navigate(`/invoices/${res?.invoice?.id}`);
        const response = await getInvoicePreview(res?.invoice?.id);
        if (!response) return;
        parseInvoicePreview(response).then(setPreview);
      }
    },
    [getInvoicePreview, navigate, preview, runSaveAndSendInvoice, runSaveAsInvoice, runSaveInvoice, setPreview]
  );
  const onSave = async (): Promise<void> => saveAndLoadInvoice('save');
  const onSend = async (): Promise<void> => saveAndLoadInvoice('send');
  const onSaveAs = async (): Promise<void> => saveAndLoadInvoice('saveAs');
  const onDownload = useCallback(async (): Promise<void> => {
    await saveAndLoadInvoice(preview?.id === 0 ? 'saveAs' : 'save');
    const { url } = await runDownloadInvoice({
      ...convertParsedInvoiceInputToInvoiceInput(preview),
      id: preview?.id || invoiceId || parseInt(window.location.pathname.split('/').pop()) || null,
    });
    if (url) saveFile(url, preview?.name);
  }, [invoiceId, preview, runDownloadInvoice, saveAndLoadInvoice]);

  const loadExistingInvoice = useCallback(
    async (invoiceId: number): Promise<void> => {
      const response = await getInvoicePreview(invoiceId);
      lastClient.current = response.output.payerProvider.id;
      parseInvoicePreview(response).then(setPreview);
    },
    [getInvoicePreview, setPreview]
  );

  const filteredRows = useMemo((): PreviewTrip[] => {
    let lastRate;
    let count = 0;
    const result = trips.reduce((acc: PreviewTrip[], trip: InvoiceTrip): PreviewTrip[] => {
      const addRow = (data: Partial<PreviewTrip>): number => acc.push({ id: uuid(), count, ...data });
      if (lastRate !== undefined && lastRate !== trip?.rate) {
        const subtotal = subtotals.find((sub: InvoiceRateSubtotal): boolean => sub?.amount === lastRate);
        if (subtotal) {
          addRow({ id: `${subtotalUuid}-${acc.length}`, subtotal: formatDollars(subtotal?.total || 0) });
          count = 0;
        }
      }
      addRow({
        id: trip?.tripId,
        trip: {
          ...trip,
          pilots: trip?.pilots || 0,
          attendants: trip?.attendants || 0,
        },
        rate: formatDollars(trip.rate || 0),
        count: ++count,
      });
      lastRate = trip?.rate;
      return acc;
    }, []);
    const subtotal = subtotals.find((sub: InvoiceRateSubtotal): boolean => sub?.amount === lastRate);
    if (subtotal) result.push({ id: `${subtotalUuid}-${result.length}`, count, subtotal: formatDollars(subtotal?.total || 0) });
    return result;
  }, [subtotals, trips]);

  const filters: InvoicePreviewFiltersState = useMemo((): InvoicePreviewFiltersState => {
    if (equal(preview, initPreview)) return;
    return Object.keys(initInvoicePreviewFiltersState).reduce((acc: InvoicePreviewFiltersState, key: string) => {
      acc[key] = preview?.[key] || initInvoicePreviewFiltersState[key];
      return acc;
    }, {} as InvoicePreviewFiltersState);
  }, [preview]);

  const lastInvoiceId = useRef<number>(null);
  useEffect((): void => {
    if (invoiceId === 0 || invoiceId === lastInvoiceId.current) return;
    lastInvoiceId.current = invoiceId;
    loadExistingInvoice(invoiceId);
  }, [invoiceId, loadExistingInvoice]);

  if (!contentHeight) return null;
  return (
    <div className="InvoicePreview-Wrapper" style={{ minHeight: contentHeight }}>
      <InvoicePreviewFilters initValue={filters} onSubmit={onSubmit} loading={loading} invoiceId={invoiceId} />
      <PageInfo>Total Trips: {trips.length}</PageInfo>
      <LoadingBlur
        loading={(invoiceId !== 0 && filteredRows.length === 0) || loading}
        label={saving || savingAs || sending ? 'Saving...' : downloading ? 'Downloading...' : 'Loading Invoice...'}
      />
      {filteredRows?.length === 0 && !loading && <NothingToInvoice />}
      {filteredRows?.length > 0 && (
        <>
          <div className="pt-4">
            <InvoicePreviewHeader
              data={{
                ...preview,
                invoiceTerms,
                company: output?.company,
                payerProvider: output?.payerProvider,
                documents: output?.invoice?.documents,
              }}
              onChange={onChange}
              onDownload={onDownload}
              onSave={!invoiceId ? onSaveAs : onSave}
              onSend={onSend}
              onSaveAs={invoiceId ? onSaveAs : undefined}
            />
          </div>
          <InvoicePreviewTable
            data={filteredRows}
            format={format}
            payerProviderId={output?.payerProvider?.id}
            onRefetch={async () => onSubmit()}
          />
          <InvoicePreviewTotals data={{ total, grandTotal }} onChange={onChange} items={items} loading={previewLoading} />
          {invoiceId === 0 && <InvoicePreviewSummary summary={summary} />}
        </>
      )}
    </div>
  );
};

const NothingToInvoice = (): JSX.Element => (
  <div className="h-100 d-flex flex-column justify-content-center flex-grow-1">
    <div className="w-100 d-flex justify-content-center">
      <h3 className="text-gray text-center">
        <i className="sv sv-inbox fa-2x"></i>
        <br />
        Nothing to Invoice
      </h3>
    </div>
  </div>
);

export default InvoicePreview;

import gql from 'graphql-tag';

export const SearchFcrTypes = gql`
  query SearchFcrTypes($input: FcrTypeConnectionInput) {
    searchFcrTypes {
      fcrTypeConnection(input: $input) {
        edges {
          node {
            id
            name
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        totalCount
      }
    }
  }
`;

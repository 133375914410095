import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';

import equal from 'fast-deep-equal/es6/react';

/* Provider
  This component is a generic provider that can be used to manage state and methods for any type of context.
  It uses React's Context API to provide the state and methods to its children components. The value of the
  context is memoized accordingly to avoid unnecessary re-renders, and the hook that it provides to access
  it uses a memoized selector to only return the selected state. This helps to optimize performance by
  preventing unnecessary re-renders of components that do not depend on the changed state.

  The use-case for this is to avoid prop-drilling, and provide a central shared state for our more complex
  components/states for children to access. The best way to think of this is a way to pass props to multiple
  children without needing to actually pass the props, and using the associated hook to access them with
  the proper selectors will prevent re-renders while allowing very specific data to be accessed.

  This should not be used on every component, only when you need to manage state across multiple components
  that are not directly related. It is also useful for managing complex state that would otherwise be
  difficult to manage with props alone.

  NOTE:
    This is meant to simplify the Context API, NOT make it better or more powerful. To avoid complexity,
    it does NOT support nested contexts on purpose. This is to keep the design of our contexts simple and
    easy to use. If you need to nest contexts bring this up with the team first because this should be a
    sign that the component design may be getting to complex. As long as nested contexts then you will need
    to use the Context API directly.
*/

type ProviderMethods = Record<string, (...args: unknown[]) => unknown>;
interface ProviderContextValue<T, M = ProviderMethods> {
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
  methods: M;
}

const ProviderContext = createContext<ProviderContextValue<any, any>>({
  value: null,
  setValue: (): void => {},
  methods: {},
});

export interface ProviderProps<T = unknown, M = ProviderMethods> {
  children?: ReactNode;
  value?: T;
  methods?: (state: T, setState: Dispatch<SetStateAction<T>>) => M;
}

const Provider = React.memo(function Provider({
  children,
  value: context = {},
  methods: unboundMethods,
}: ProviderProps<typeof context, typeof unboundMethods>): JSX.Element {
  const [value, setValue] = useState<typeof context>(context);
  const methods = useMemo(
    (): typeof unboundMethods => (unboundMethods ? unboundMethods(value, setValue) : {}),
    [unboundMethods, value, setValue]
  );
  useEffect((): void => {
    if (equal(value, context)) return;
    setValue(context);
  }, [context, value]);
  return <ProviderContext.Provider value={{ value, setValue, methods }}>{children}</ProviderContext.Provider>;
}, equal);

/* useProvider
  This hook is used to access the state and methods provided by the Provider component. It takes an optional
  selector function that can be used to select a specific part of the state. The hook returns an array
  containing the selected state, the setState function, and the methods provided by the Provider component.

  This hook is useful for accessing the state and methods provided by the Provider component in a more
  convenient way by wrapping the standard Context API, and memoizing it properly. The purpose of this hook
  and component it to avoid the boilderplate of using the Context API directly, and to provide a more optimized
  way of accessing the state and methods.
*/

export const useProvider = <T, S = T, M = ProviderMethods>(selector?: (state: T) => S): [S, Dispatch<SetStateAction<T>>, M] => {
  const { value, setValue, methods } = useContext<ProviderContextValue<T, M>>(ProviderContext);
  const selectedValue = useMemo((): S => (selector ? selector(value) : (value as unknown as S)), [selector, value]);
  const baseState = useMemo(
    (): [S, Dispatch<SetStateAction<T>>, M] => [selectedValue, setValue, methods],
    [selectedValue, setValue, methods]
  );
  return baseState;
};

export default Provider;

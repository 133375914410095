import { Datetime, getClasses, getResultFromState, zeroPadFlightNumber } from '@/utils';
import { InvoiceFormatEnum, InvoiceTrip } from '@/models/gen/graphql';
import { InvoicePreviewTableProvider, useInvoicePreviewTable } from '@/pages/InvoicePreview/InvoicePreviewTable';
import { RefObject, forwardRef } from 'react';

import CrewIdCell from '@/pages/InvoicePreview/components/CrewIdCell';
import { DATE_FE_FORMAT } from '@/constants';
import { PreviewTrip } from '@/pages/InvoicePreview';
import RateCell from '@/common/RateCell';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';

export type SelectedRowAndFormat = { row: PreviewTrip; format: InvoiceFormatEnum; payerProviderId: string };
const InvoicePreviewRow = ({ id }: { id: string }, ref: RefObject<HTMLDivElement>): JSX.Element => {
  const [{ row, format }, setPreview, { onRefetch }] = useInvoicePreviewTable<SelectedRowAndFormat>(
    ({ trips, format, payerProviderId }: InvoicePreviewTableProvider): SelectedRowAndFormat => ({
      row: trips.get(id),
      format,
      payerProviderId,
    })
  );
  const { count = 0, subtotal, trip = {} as InvoiceTrip } = row || {};

  const setRow = (update: PreviewTrip): void => {
    setPreview(
      (current: InvoicePreviewTableProvider): InvoicePreviewTableProvider => ({
        ...current,
        trips: new Map(current.trips.set(id, getResultFromState(update, current.trips.get(id)))),
      })
    );
    onRefetch();
  };

  return (
    <SimpleTableRow
      id={id}
      className={getClasses('InvoicePreviewRow', subtotal !== undefined ? 'SUBTOTAL' : undefined)}
      data={trip}
      ref={ref}
    >
      <SimpleTableCell name="INDEX">{count}</SimpleTableCell>
      <SimpleTableCell name="TYPE">{trip?.type || '--'}</SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_DATE">
        {trip?.scheduled?.trim?.() ? new Datetime(trip?.scheduled).format(DATE_FE_FORMAT) : ' '}
      </SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_TIME">{trip?.scheduled?.trim?.() ? new Datetime(trip?.scheduled).time : ' '}</SimpleTableCell>
      <SimpleTableCell name="AIRPORT_CODE">{trip?.airportCode || '--'}</SimpleTableCell>
      <SimpleTableCell name="SERVICER_IATA_AIRLINE_CODE">{trip?.servicerIataAirlineCode || '--'}</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">
        {trip?.name ||
          (trip?.flightNumber !== undefined && trip?.flightNumber !== null ? zeroPadFlightNumber(trip?.flightNumber || 0) : '') ||
          '--'}
      </SimpleTableCell>
      {format === InvoiceFormatEnum.Crew && <CrewIdCell />}
      <SimpleTableCell name="PILOTS">{trip?.pilots || 0}</SimpleTableCell>
      <SimpleTableCell name="ATTENDANTS">{trip?.attendants || 0}</SimpleTableCell>
      <SimpleTableCell name="PU_LOCATION">{trip?.puLocation || '--'}</SimpleTableCell>
      <SimpleTableCell name="DO_LOCATION">{subtotal === undefined ? trip?.doLocation || '--' : 'SUBTOTAL:'}</SimpleTableCell>
      {subtotal === undefined ? (
        <RateCell id={trip.tripId} rate={trip.rate} selected={[]} onChange={setRow} />
      ) : (
        <SimpleTableCell name="RATE">{subtotal || '0.00'}</SimpleTableCell>
      )}
    </SimpleTableRow>
  );
};

export default forwardRef(InvoicePreviewRow);

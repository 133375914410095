import CurrencyInput, { CurrencyInputProps } from '@/components/CurrencyInput';
import { Dispatch, KeyboardEvent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Rate, RunRateAmountOnTripResponse, Trip } from '@/models/gen/graphql';
import { Validation, getClasses, handleError } from '@/utils';

import { Button } from 'react-bootstrap';
import LoadingSpinner from '@/components/LoadingSpinner';
import RateDisplay from '@/common/RateDisplay';
import { TripTableState } from '@/features/Trips/components/TripsTable/hook';
import { useRunRateAmountOnTrips } from '@/api/services/trips/runRateAmountOnTrips';

export type RateInputProps = {
  rowId: string;
  rate: number;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
} & CurrencyInputProps;

const RateInput = ({ rate, rowId, selected, onSetRow, editing, setEditing, ...currencyInputProps }: RateInputProps): React.JSX.Element => {
  const [value, setValue] = useState('');
  const [{ loading }, { fetch }] = useRunRateAmountOnTrips();

  const runRateAmountOnTrips = useCallback(
    async (tripIds: string[], amount: number): Promise<[Partial<Trip>, string[]]> => {
      // TODO: Move this logic into the service itself. This is too complicated for a component.
      try {
        const payload = tripIds.map((tripId: string): { tripId: string; amount: number } => ({
          tripId,
          amount,
        }));
        const res = await fetch(payload);
        if (!res?.length) throw new Error('Failed to update rate.');
        const { rateId, rateGroupId } = res[0];
        const updates: Partial<Trip> = {
          id: rowId,
          rate: {
            id: rateId,
            rateGroupId: rateGroupId,
            rate: amount,
          } as Rate,
        };
        const updatedIds = res.map(({ tripId }: RunRateAmountOnTripResponse): string => tripId);
        return [updates, updatedIds];
      } catch (err) {
        handleError(err, { notification: { title: 'Update Trip Rate' } });
        return undefined;
      }
    },
    [rowId, fetch]
  );
  const onSubmitInlineRate = useCallback(
    async (value: string): Promise<void> => {
      const amount = parseFloat(`${value}`);
      if (!Validation.isNumber(amount)) throw new Error('Invalid amount.');
      setEditing(false);
      const res = await runRateAmountOnTrips(selected, amount);
      if (res !== undefined) {
        const [updates, updatedIds] = res;
        onSetRow(updates, updatedIds);
      }
      setEditing(false);
    },
    [selected, onSetRow, setEditing, runRateAmountOnTrips]
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement> & { target: { value?: string } }): void => {
      switch (event.key) {
        case 'Enter':
          // Submit the rate, and fall through to close the edit.
          onSubmitInlineRate(event.target.value);
        case 'Escape':
          // Reset local value and finish editing.
          setValue(`${rate ?? ''}`);
          setEditing(false);
          break;
        default:
          // Do nothing so the input can be edited.
          break;
      }
    },
    [rate, setEditing, onSubmitInlineRate]
  );
  const onBlur = (): void => setEditing(false);

  useEffect((): void => {
    if (`${rate}` === value) return;
    setValue(`${rate ?? ''}`);
  }, [rate, value]);

  if (loading)
    return (
      <span>
        <LoadingSpinner size="sm" />
      </span>
    );
  if (editing)
    return (
      <CurrencyInput
        name="EDIT_RATE"
        {...currencyInputProps}
        className={getClasses('RateInput', currencyInputProps.className)}
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus
      />
    );
  return (
    <Button className="RateInput" name="EDIT_RATE" variant="icon" onClick={(): void => setEditing(true)}>
      {Validation.isNumber(rate) ? <RateDisplay style={{ lineHeight: '1.25rem' }}>{rate}</RateDisplay> : '--'}
    </Button>
  );
};

export default RateInput;

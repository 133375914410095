import { CreateStopInput, CreateTripInput, FlagWithTrip, Stop, Trip } from '@/models/gen/graphql';
import { Datetime, Validation, createNotification, getDiff } from '@/utils';
import { createContext, useContext, useMemo } from 'react';

import { Toast } from '@/models';
import combineTrips from '@/api/services/trips/combineTrips';
import { createActivityForTrip } from '@/api/services/trips/createActivityBulk';
import createCommunicationBulk from '@/api/services/trips/createCommunicationBulk';
import createFcrBulk from '@/api/services/trips/createFcrBulk';
import createFlagHasTripBulk from '@/api/services/trips/createFlagHasTripBulk';
import createStopBulk from '@/api/services/trips/createStopBulk';
import createTrips from '@/api/services/trips/createTrips';
import deleteFlagHasTripBulk from '@/api/services/trips/deleteFlagHasTripBulk';
import deleteStopBulk from '@/api/services/trips/deleteStopBulk';
import updateStopBulk from '@/api/services/trips/updateStopBulk';
import updateTrackFlightBulk from '@/api/services/trips/updateTrackFlightBulk';
import updateTripBulk from '@/api/services/trips/updateTripBulk';
import useActions from './useActions';
import { useSearchCombinableTrips } from '@/api/services/trips/searchCombinableTrips';

interface TripActions {
  __name: string;
  searchCombinableTrips: any;
  refetchCombinableTrips: any;
  createTrips: any;
  updateTripBulk: any;
  createStopBulk: any;
  updateStopBulk: any;
  deleteStopBulk: any;
  combineTrips: any;
  createCommunicationBulk: any;
  createFcrBulk: any;
  createFlagHasTripBulk: any;
  deleteFlagHasTripBulk: any;
  handleUpdateTripBulk: (update: any, selected: any[]) => Promise<void>;
  handleCreateTripBulk: (update: any) => Promise<void>;
  handleSubmitFcr: (data: any) => Promise<void>;
  handleSubmitCombine: (data: any) => Promise<void>;
  handleSubmitCommunication: (data: { tripId: string; message: string }) => Promise<any>;
  handleSubmitFlag: (data: any) => Promise<void>;
}

const TripsContext = createContext({});
const initUseTripsState = {};

const useTrips = (): any[] => {
  const [{ data: combineResults, loading: loadingCombinableTrips }, { fetch: searchCombinableTrips, refetch: refetchCombinableTrips }] =
    useSearchCombinableTrips();

  const [state, setState, createProvider] = useActions(initUseTripsState, (): TripActions => {
    // Start Methods

    const handleCreateTripBulk = async ({ stops = [], flags = [], scheduledDays = [], ...create }: any): Promise<void> => {
      // ensure properties are the correct type
      create.fromManifest = create.fromManifest !== undefined ? !!create.fromManifest : true;
      if (create?.flightNumber) create.flightNumber = parseInt(create?.flightNumber);

      // create trip(s)
      const time = new Datetime(create?.scheduled || '').fullTime || '00:00:00';
      const createTripsPayload: CreateTripInput[] = scheduledDays.map((day: string): CreateTripInput => {
        const scheduled = new Datetime(day).setTime(time).toString();
        return { ...create, scheduled };
      });
      const response: any = await createTrips(createTripsPayload);
      for (let i: number = 0; i < response.length; i++) {
        const error: string = response[i].error;
        if (error) {
          createNotification(error, Toast.Type.DANGER, 'Create Trip');
          continue;
        } else {
          createNotification(
            `Trip for flight ${create.flightNumber || create.loopName} successfully created`,
            Toast.Type.SUCCESS,
            'Create Trip'
          );
        }

        const tripId: string = response[i]?.node?.id;

        // create stops if any were added
        if ((stops || []).length) {
          const invalid = [];
          const createStopsPayload: CreateStopInput[] = (stops || [])
            .filter((node: any): boolean => !Validation.isValidUUID(node.id))
            .map((stop: any): any => {
              // validate stop
              ['locationId', 'scheduled'].forEach((field) => {
                if (!stop[field]) {
                  if (!invalid.includes(field)) invalid.push(field);
                }
              });
              return { ...stop, type: stop.type || 'DO', tripId };
            });

          if (invalid.length > 0) {
            createNotification(`Invalid fields: ${invalid.sort().join(', ')}`, Toast.Type.DANGER, 'Create Stops');
            return;
          }

          // call create stops
          if (createStopsPayload.length > 0) await createStopBulk(createStopsPayload);
        }

        // create flags
        if (flags.length) {
          const createFlags: any = flags.map((flagId: string): any => ({ flagId, tripId }));
          createFlagHasTripBulk(createFlags);
        }
      }
    };

    const cloneTrips = async (
      { increment = false, days = 0, partial }: { partial: Trip; days: number; increment: boolean },
      selected: Trip[]
    ): Promise<void> => {
      // Cloned Trips
      const createTripsPayload: CreateTripInput[] = Object.values(selected).map((trip: any): CreateTripInput => {
        const result: CreateTripInput = { ...trip, ...partial };
        const scheduled = new Datetime(trip?.scheduled);
        if (increment) scheduled.add(days, 'days');
        result.scheduled = scheduled.toString();
        result.companyId = '';
        result.fromManifest = result.fromManifest !== undefined ? !!result.fromManifest : true;
        return result;
      });
      const response = await createTrips(createTripsPayload);
      for (let i: number = 0; i < response.length; i++) {
        const error: string = response[i].error;
        if (error) {
          createNotification(
            `Trip for flight ${createTripsPayload[i].flightNumber || createTripsPayload[i].loopName}: ${error}`,
            Toast.Type.DANGER,
            'Create Trip'
          );
          continue;
        } else {
          createNotification(
            `Trip for flight ${createTripsPayload[i].flightNumber || createTripsPayload[i].loopName} successfully created`,
            Toast.Type.SUCCESS,
            'Create Trip'
          );
        }
      }
    };

    const handleUpdateTripBulk: any = async ({ copy = false, ...updateObj }: any, selected: Trip[]): Promise<void> => {
      const { increment = false, days = 0, stops = [], flags: updateFlags = [], comments, trackFlight, ...update } = updateObj;

      // stops are going to be handled seperately
      const { stops: originalStops, trackFlight: originalTrackFlight, ...original }: any = selected?.[0] || {};
      const [partial] = getDiff(original, update);

      if (copy) return cloneTrips({ partial, increment, days }, selected);

      // Update Trips Flow
      if (Object.values(partial).length > 0) {
        const bundle = [];
        const trips = selected;
        for (let t = 0; t < trips.length; t++) {
          const tripId = trips[t]?.id;
          const initTrip = trips[t];
          //apply changes to all selected trips
          const output: Trip = { ...partial, id: tripId };
          if (increment) output.scheduled = new Datetime(initTrip?.scheduled).add(days, 'days').toString();
          if (output?.flightNumber) output.flightNumber = parseInt(output?.flightNumber + '');
          bundle.push(output);
        }

        await updateTripBulk(bundle);
      }

      // Handle Update Track Flight Flow
      const [partialTrackFlight] = getDiff(originalTrackFlight, trackFlight);
      if (original.trackFlightId && Object.values(partialTrackFlight).length > 0) {
        await updateTrackFlightBulk([{ ...partialTrackFlight, trackerProvider: 'portal', id: original.trackFlightId }]);
      }

      // Handle Crud Flags Flow
      if (selected.length < 2) {
        const flags = updateFlags?.map((node: any): string => node.id || node);
        const createFlagsPayload = [];
        const deleteFlagsPayload = [];
        selected.forEach((trip: Trip): void => {
          const originalFlags: FlagWithTrip[] = trip.flags || [];

          // Check update flags to see if the original flags have it already
          flags?.forEach((flagId: string): void => {
            const foundFlag = !!originalFlags.filter((flag: FlagWithTrip): boolean => flag.id === flagId).length;
            if (!foundFlag) createFlagsPayload.push(flagId);
          });

          // Check original flags to see if it's removed from the update flags
          originalFlags.forEach((flag: FlagWithTrip): void => {
            const foundFlag: boolean = !!flags.filter((flagId: string): boolean => flag.id === flagId).length;
            if (!foundFlag) deleteFlagsPayload.push(flag.id);
          });
        });

        // call Flag CRUD
        if (createFlagsPayload.length > 0 || deleteFlagsPayload.length > 0)
          handleSubmitFlag({ updates: { creates: createFlagsPayload, deletes: deleteFlagsPayload }, tripId: update.id });
      }

      // Handle Crud Stops Flow
      if (selected.length === 1) {
        const invalid: string[] = [];

        // Create Stops
        const createStopsPayload = (stops || [])
          .filter((node: any): boolean => !Validation.isValidUUID(node.id))
          .map((stop: any): any => {
            // validate stop
            ['locationId', 'scheduled'].forEach((field) => {
              if (!stop[field]) {
                if (!invalid.includes(field)) invalid.push(field);
              }
            });
            return { ...stop, scheduled: new Datetime(stop?.scheduled).toString(), type: stop.type || 'DO', tripId: original.id };
          });

        if (invalid.length > 0) {
          createNotification(`Invalid fields: ${invalid.sort().join(', ')}`, Toast.Type.DANGER, 'Create Stops');
          return;
        }

        // Call Create Stops
        if (createStopsPayload.length > 0) await createStopBulk(createStopsPayload);

        // Update Stops
        const updateStopsPayload = [];
        (stops || [])
          .filter((node: any): boolean => Validation.isValidUUID(node.id))
          .forEach((stop: Stop): void => {
            const initStop = (originalStops || []).filter((node: Stop): boolean => node.id === stop.id)[0];
            const [partial] = getDiff(initStop, stop);
            if (Object.values(partial).length) updateStopsPayload.push({ ...partial, id: stop.id });
          });

        // Call Update Stops
        if (updateStopsPayload.length > 0) await updateStopBulk(updateStopsPayload);

        // Delete Stops
        const deleteStopsPayload = (originalStops || [])
          .filter((node: any): boolean => !(stops || []).filter((el: any): boolean => (el.id || el) === node.id).length)
          .map((node: any): string => node.id);

        // Call Delete Stops
        if (deleteStopsPayload.length > 0) await deleteStopBulk(deleteStopsPayload);
      }

      // Handle Create Comments
      if (comments?.length) {
        const createCommentsPayload: Array<{ tripId: string; comment: string; field: string }> = selected.map(
          ({ id: tripId }: Trip): { tripId: string; comment: string; field: string } => ({
            field: 'Comment',
            comment: comments,
            tripId,
          })
        );
        await createActivityForTrip(createCommentsPayload);
      }
    };

    const handleSubmitFcr = async ({ tripId, fcrTypeId, description, cause }: any): Promise<void> => {
      if (!description || !fcrTypeId) return;

      await createFcrBulk([
        {
          tripId,
          fcrTypeId,
          description,
          cause,
        },
      ]);
    };

    const handleSubmitCombine = async ({ updates: { combineTripIds, unCombinePayload }, ...data }: any): Promise<void> => {
      let changesMade = false;
      if (combineTripIds.length > 0) {
        changesMade = true;
        await combineTrips({ driverId: data.driverId, vehicleId: data.vehicleId }, combineTripIds);
      }
      if (unCombinePayload.length > 0) {
        changesMade = true;
        const updateResponse = await updateTripBulk(unCombinePayload, false);
        let updated = 0;
        for (let i = 1; i <= (updateResponse || []).length; i++) {
          const node = updateResponse[i - 1];
          if (node.error) {
            return createNotification(node.error.message || 'Failed to uncombine trips', Toast.Type.DANGER, 'Uncombine Trips');
          }
          updated += node.updated || 0;
          if (i === updateResponse.length && updated > 0) return createNotification('Success', Toast.Type.SUCCESS, 'Uncombine Trips');
        }
      }
      if (!changesMade) return createNotification('No changes were made.', Toast.Type.WARNING, 'Combine Trips');
    };

    const handleSubmitCommunication: any = async ({ tripId, message }: { tripId: string; message: string }): Promise<any> => {
      if (!message || message === '') {
        return createNotification('Unable to create a communication without a message', Toast.Type.WARNING, 'Create Communication on Trip');
      }
      await createCommunicationBulk([{ tripId, message }]);
    };

    // Handle Adding Flag to Trip
    const handleSubmitFlag = async ({ updates: { creates, deletes }, tripId }: any): Promise<void> => {
      if (!creates?.length && !deletes?.length) {
        return createNotification('no changes made', Toast.Type.WARNING, 'Assign Flag to Trip');
      }
      if (creates?.length) {
        await createFlagHasTripBulk(creates.map((flagId: string): { flagId: string; tripId: string } => ({ flagId, tripId })));
      }
      if (deletes?.length) {
        await deleteFlagHasTripBulk(deletes.map((flagId: string): { flagId: string; tripId: string } => ({ flagId, tripId })));
      }
    };

    return {
      __name: 'useTrips',
      searchCombinableTrips,
      refetchCombinableTrips,
      createTrips,
      updateTripBulk,
      createStopBulk,
      updateStopBulk,
      deleteStopBulk,
      combineTrips,
      createCommunicationBulk,
      createFcrBulk,
      createFlagHasTripBulk,
      deleteFlagHasTripBulk,
      handleUpdateTripBulk,
      handleCreateTripBulk,
      handleSubmitFcr,
      handleSubmitCombine,
      handleSubmitCommunication,
      handleSubmitFlag,
    };
    // End Methods
  });
  const Provider = useMemo((): any => createProvider(TripsContext), []);

  return [{ ...state, combineResults }, setState, { loading: loadingCombinableTrips }, Provider];
};
const useTripsContext = (): {} => useContext(TripsContext);

export default useTrips;
export { TripsContext, useTripsContext };

import SimpleTableCell, { SimpleTableCellProps } from '@/components/SimpleTable/SimpleTableCell';
import { useMemo, useState } from 'react';

import RateInput from '@/common/RateInput';
import React from 'react';
import SuggestRate from '@/common/RateCell/SuggestRate';
import { Trip } from '@/models/gen/graphql';
import { getClasses } from '@/utils';

export type RateCellProps = {
  id: string;
  rate: number;
  selected?: string[];
  onChange: (update: unknown) => void;
} & Partial<Omit<SimpleTableCellProps, 'onChange'>>;

const RateCell = ({ id, rate, selected, onChange, ...cellProps }: RateCellProps): JSX.Element => {
  const [editing, setEditing] = useState(false);

  const dedupeSelected = useMemo((): string[] => Array.from(new Set([...(selected || []), id])), [selected, id]);

  const handleChange = (update: Partial<Trip>): void =>
    onChange((current) => ({ ...current, rate: update.rate.rate, trip: { ...current.trip, rate: update.rate.rate } }));

  return (
    <SimpleTableCell name="RATE" className={getClasses('RateCell', 'w-sm-grow d-flex gap-2', cellProps.className)} {...cellProps}>
      {!editing && <SuggestRate rowId={id} selected={dedupeSelected} onSetRow={handleChange} />}
      <RateInput rate={rate || 0} rowId={id} selected={dedupeSelected} onSetRow={handleChange} editing={editing} setEditing={setEditing} />
    </SimpleTableCell>
  );
};

export default React.memo(RateCell);

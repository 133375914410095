import { Rate, Trip, UpdateRateOnTripResponse } from '@/models/gen/graphql';
import { useCallback, useEffect, useState } from 'react';
import useTripTableState, { TripTableState } from '@/features/Trips/components/TripsTable/hook';

import { Button } from 'react-bootstrap';
import LoadingSpinner from '@/components/LoadingSpinner';
import Tooltip from '@/components/Tooltip';
import { handleError } from '@/utils';
import updateRateOnTrips from '@/api/services/rates/updateRateOnTrips';
import useDelayCallback from '@/hooks/useDelayCallback';
import { useGetCorrectRatesForTrips } from '@/api/services/rates/getCorrectRatesForTrips';
import { useRatesReportModal } from '@/components/RateReportModal';

const SuggestRate = ({
  rowId,
  selected,
  onSetRow,
  onRefetch,
}: {
  rowId: string;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
  onRefetch?: () => void;
}): React.JSX.Element => {
  const [{ data = [], loading }, { fetch: getCorrectRatesForTrips }] = useGetCorrectRatesForTrips();
  const [called, setCalled] = useState(false);
  const setState = useTripTableState(({ setState }) => setState);
  const showRateReportModal = useRatesReportModal(({ setState }) => setState);

  const onEditRateReport = useCallback(
    () =>
      showRateReportModal({
        show: true,
        tripIds: Object.keys(selected),
        onSubmit: async () => {
          // clear selected state on trip modal submit
          setState((current) => ({ ...current, selected: new Map() }));
          onRefetch?.();
        },
      }),
    [showRateReportModal, selected, setState, onRefetch]
  );

  const suggestedRate = data[0]?.newRate;
  const displayRateReport = selected?.length > 1 && selected?.includes(rowId);

  const applyRateSuggestion = useCallback(async (): Promise<UpdateRateOnTripResponse[]> => {
    try {
      const res = await updateRateOnTrips([{ rateHistoryId: suggestedRate?.id, tripId: rowId }]);
      if (!res.length) throw new Error('failed to update rate');
      const updates: Partial<Trip> = {
        id: rowId,
        rate: {
          id: suggestedRate?.id,
          rateGroupId: suggestedRate?.rateGroupId,
          rate: suggestedRate?.rate,
        } as Rate,
      };

      const tripIds = res.map((a): string => a.tripId);

      onSetRow(updates, tripIds);
      return res;
    } catch (err) {
      handleError(err, { notification: { title: 'Update Trip Rate' } });
    }
  }, [suggestedRate?.id, suggestedRate?.rateGroupId, suggestedRate?.rate, rowId, onSetRow]);

  const [handleMouseEnter, handleMouseLeave] = useDelayCallback((): void => {
    if (displayRateReport) return;
    setCalled(true);
    getCorrectRatesForTrips([rowId]);
  }, 250);

  useEffect((): (() => void) => {
    return (): void => setCalled(false);
  }, []);

  return (
    <Tooltip
      placement="left"
      interactive={!!(displayRateReport || suggestedRate)}
      content={
        <div className="d-flex gap-2">
          {displayRateReport && (
            <Button size="sm" onClick={onEditRateReport} className="{white-space:nowrap}">
              Rate Report
            </Button>
          )}
          {/* display loading spinner if the request is not called */}
          {(!called || loading) && !displayRateReport && <LoadingSpinner size="sm" />}
          {called && !loading && !displayRateReport && !suggestedRate && (
            <span className="{white-space:nowrap;line-height:2rem;}">No Suggested Rate</span>
          )}
          {called && !loading && !displayRateReport && suggestedRate && (
            <>
              <span className="{white-space:nowrap;line-height:2rem;}">{`Suggested ${suggestedRate.type} $${suggestedRate.rate.toFixed(2)}`}</span>
              <Button size="sm" onClick={applyRateSuggestion}>
                Apply
              </Button>
            </>
          )}
        </div>
      }
    >
      <i
        className="fa fa-circle-info pointer d-print-none {opacity:0.4;}"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  );
};

export default SuggestRate;

import { Button, Card, Col, Row } from 'react-bootstrap';
import { Datetime, QueryInputType, Validation, queryInput } from '@/utils';
import { Fcr, FcrType, FcrTypeEdge, SortDirectionEnum } from '@/models/gen/graphql';
import React, { useEffect, useMemo, useRef } from 'react';

import EditModal from '@/components/EditModal/new';
import FormField from '@/components/FormField';
import RadioButton from '@/components/RadioButton';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import { createComponentState } from '@/state';
import deleteFcrBulk from '@/api/services/fcrs/deleteFcrBulk';
import getFcrTypes from '@/queries/fcrs/getFcrTypes';
import useDb from '@/hooks/useDb';
import useOnChange from '@/hooks/useOnChange';
import { useSearchFcrs } from '@/api/services/fcrs/searchFcrs';
import useTrips from '@/hooks/useTrips';

type EditFcrModalState = {
  show?: boolean;
  loading?: boolean;
  tripId?: string;
  servicerIataAirlineCode?: string;
  flightNumber?: string;
  scheduled?: string;
  fcrTypeId?: string;
  description?: string;
  cause?: string;
  onSubmit?: (data?: any) => Promise<void>;
  onHide?: () => void;
};

const initEditFcrModalState: EditFcrModalState = {
  show: false,
  loading: false,
  tripId: '',
  servicerIataAirlineCode: '',
  flightNumber: '',
  scheduled: '',
  fcrTypeId: '',
  description: '',
  cause: '',
  onSubmit: async (): Promise<void> => undefined,
  onHide: (): void => undefined,
};

export const useEditFcrModal = createComponentState(initEditFcrModalState);

const EditFcr: any = (): JSX.Element => {
  const [state, setState] = useEditFcrModal(({ state, setState }) => [state, setState]);
  const {
    show = false,
    loading = false,
    tripId = '',
    servicerIataAirlineCode = '',
    flightNumber = '',
    scheduled = '',
    fcrTypeId = '',
    description = '',
    cause = '',
    onHide = () => undefined,
    onSubmit = async () => undefined,
  } = state;
  const onChange = useOnChange(setState);
  const deletedFcrs = useRef(false);

  // Init State
  const [validity, valid] = useMemo((): [Record<string, { valid: boolean }>, boolean] => {
    const result: Record<string, { valid: boolean }> = {};
    result.description = {
      valid: (description || '').trim().length > 0 && Validation.isAlphanumeric(description),
    };
    result.cause = {
      valid: (cause || '').trim().length > 0 && Validation.isAlphanumeric(cause),
    };
    result.fcrTypeId = {
      valid: Validation.isValidUUID(fcrTypeId),
    };
    result.tripId = {
      valid: Validation.isValidUUID(tripId),
    };
    const isValid = !(Object.values(result).filter((item: { valid: boolean }): boolean => !item.valid).length > 0);
    return [result, isValid];
  }, [description, cause, fcrTypeId, tripId]);

  // Init Queries
  const [, { handleSubmitFcr }] = useTrips();
  const [{ data: searchFcrsData, loading: searchFcrsLoading }, { refetch: refetchFcrs }] = useSearchFcrs();
  const { rows = [] } = searchFcrsData || {};

  const { data: FcrTypesData = {} } = useDb('FcrTypes', getFcrTypes.query);
  const getFcrTypesData: any = useMemo(
    (): { rows: FcrType[] } => ({
      rows: (FcrTypesData.searchFcrTypes?.fcrTypeConnection?.edges || []).map((edge: FcrTypeEdge): FcrType => edge.node),
    }),
    [FcrTypesData]
  );

  // Crud Handlers
  const handleDeleteFcrs = async (id: string): Promise<void> => {
    await deleteFcrBulk([id]);
    deletedFcrs.current = true;
    refetchFcrs();
  };

  const handleSubmit = async (): Promise<void> => {
    setState((current) => ({ ...current, loading: true }));
    try {
      await handleSubmitFcr({ tripId, fcrTypeId, description, cause });
      onSubmit?.();
      onHide?.();
    } catch (error) {
      console.error(error);
    }
    setState({ show: false, loading: false });
  };

  const handleHide = (): void => {
    if (deletedFcrs.current) {
      handleSubmit();
    } else {
      onHide?.();
      setState({ show: false });
    }
  };

  useEffect((): void => {
    if (!tripId) return;
    refetchFcrs([
      {
        tripId: queryInput(tripId),
        createdAt: queryInput([], QueryInputType.ISNOTNULL, SortDirectionEnum.Desc),
      },
    ]);
  }, [refetchFcrs, tripId]);

  const title = `Complaint Details - ${(servicerIataAirlineCode || '').toUpperCase()}${flightNumber || ''} ${
    scheduled ? new Datetime(scheduled).frontendDatetimeShort : ''
  }`;

  return (
    <EditModal
      name="EditFcr"
      icon="sv sv-fcr"
      show={show}
      onHide={handleHide}
      title={title}
      options={{ Footer: null }}
      loading={searchFcrsLoading || loading}
      size="xl"
    >
      <Row className="d-flex justify-content-center mt-3">
        {/* Radio Component */}
        {getFcrTypesData.rows.map(
          (fcrType: FcrType, idx: number): JSX.Element => (
            <Col key={idx}>
              <RadioButton
                onClick={() => setState((current) => ({ ...current, fcrTypeId: fcrType.id }))}
                checked={fcrTypeId === fcrType.id}
              >
                {fcrType.name}
              </RadioButton>
            </Col>
          )
        )}
      </Row>
      <div className="my-3">
        <FormField
          label="Description:"
          name="description"
          type="textarea"
          onChange={onChange}
          placeholder="Description"
          value={description || ''}
          valid={validity?.description?.valid}
          condensed
        />
        <FormField
          label="Root Cause:"
          name="cause"
          type="textarea"
          onChange={onChange}
          placeholder="Root Cause"
          value={cause || ''}
          valid={validity?.cause?.valid}
        />
        <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
          <div className="d-flex justify-content-end">
            <Button className="d-block px-5" name="SUBMIT" variant="green" disabled={!valid} onClick={handleSubmit}>
              ADD COMPLAINT
            </Button>
          </div>
        </TippyWhen>
      </div>
      {/* Fcrs */}
      {rows.length > 0 && (
        <div className="Fcrs d-flex flex-column">
          <div className="Fcrs-Scroll-Container">
            {rows.map((fcr: Fcr, idx: number) => (
              <FcrCard key={idx} data={fcr} onDelete={() => handleDeleteFcrs(fcr.id)} />
            ))}
          </div>
        </div>
      )}
    </EditModal>
  );
};

const FcrCard = ({ data: fcr, onDelete }: { data: Fcr; onDelete: (fcr: Fcr) => void }): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{fcr?.fcrType?.name}</Card.Title>
        <Card.Text>{fcr?.description}</Card.Text>
        {fcr?.cause && <Card.Subtitle className="mb-2 text-muted">Cause: {fcr?.cause}</Card.Subtitle>}
        <Card.Footer>
          <span className="card-footer-details">
            <Card.Text>{fcr?.createdAt ? new Datetime().setAsUTC(fcr?.createdAt).toLocaleDatetime().frontendDatetime : '--'}</Card.Text>
            <Tippy content="Delete Complaint">
              <Button name="DELETE" variant="transparent" onClick={(): void => onDelete(fcr)}>
                {<i className="sv sv-trash2 fs-5 text-danger" />}
              </Button>
            </Tippy>
          </span>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default EditFcr;
